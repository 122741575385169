import api from "@/services/api";

const authModule = {
  namespaced: true, 
  state: {
    isAuthenticated: false,
    user: null,
    isFetchingAuth: false 
  },
  mutations: {
    SET_AUTH(state, user) {
      state.isAuthenticated = !!user;
      state.user = user;
    },
    SET_FETCHING_AUTH(state, value) {
      state.isFetchingAuth = value;
    }
  },
  actions: {
    async fetchUser({ commit, state }) {
      if (state.isFetchingAuth) return; 

      commit("SET_FETCHING_AUTH", true);
      try {
        const response = await api.get("/authentication/validate");
        if (response.data.authenticated) { 
          commit("SET_AUTH", response.data);
        } else {
          commit("SET_AUTH", null);
        }
      } catch (error) {
        console.error("Failed to fetch authentication:", error);
        commit("SET_AUTH", null);
      }
      commit("SET_FETCHING_AUTH", false);
    }
  }
};

export default authModule;
