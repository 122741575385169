import Vue from "vue";
import VueRouter from "vue-router";
import api from "@/services/api";

Vue.use(VueRouter);

const routes = [
  { path: "/not-authorized", name: "notAuthorized", component: () => import("../views/NotAuthorizedView.vue") },
  { path: "/", name: "splashView", component: () => import("../views/SplashView.vue") },
  { path: "/clock-in", name: "clockIn", props: true, component: () => import("../views/ClockIn.vue"), meta: { requiresAuth: true } },
  { path: "/home/:id?", name: "home", component: () => import("../views/HomeView.vue"), meta: { requiresAuth: true } },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL || "/",
  routes
});


router.beforeEach(async (to, from, next) => {

  if (from.path === "/homeview" && to.path === "/") {
    next(false);
  } else {
    next();
  }

  if (!to.meta.requiresAuth) {
    return next();
  }

  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.authenticated) {
    return next();
  }

  try {
    const response = await api.get("/authentication/validate", { withCredentials: true });
    if (response.data.authenticated) {
      localStorage.setItem("user", JSON.stringify(response.data));
      return next();
    }
  } catch (error) {
    console.error("Authentication check failed:", error);
  }

  return next("/");
});

export default router;
